import Divider from 'antd/lib/divider';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import React, { useState } from 'react';
import FormBuilder from 'antd-form-builder';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import LoginStyle from './index.style';
import Logo from '../../assets/Logo/index.logo';
import { useApp } from '../../contexts/app';
import localStorages from '../../utils/localStorages';
import { Redirect, useHistory } from 'react-router-dom';
import { SET_USER } from '../../contexts/types';
import loginServices from './index.service';
import { notifySuccess, notifyError } from '../../utils';
import { KeyConstants } from '../../utils/contants/key-constants';

const Login = (props) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [state, dispatch] = useApp();
    const [remember, setRemember] = useState(false);
    const [initialValues, setInitialValues] = useState({
        username: '',
        password: '',
        remember: true,
    });
    const [loading, setLoading] = useState(false);

    // React.useEffect(() => {
    //     if (localStorages().get('APP_USER')) {
    //         let rememberUser = localStorages().get('APP_USER');
    //         setInitialValues(rememberUser);
    //     }
    // }, []);

    const meta = {
        column: 1,
        initialValues: initialValues,
        fields: [
            {
                key: 'username',
                label: 'Username',
                required: true,
                message: 'Username harus diisi',
            },
            {
                key: 'password',
                label: 'Password',
                widget: 'password',
                required: true,
                message: 'Password harus diisi',
            },
        ],
    };

    const onFinish = async (values) => {
        setLoading(true);
        if (remember) {
            localStorages().save(KeyConstants.APP_USER, JSON.stringify(values));
        } else {
            localStorages().remove(KeyConstants.APP_USER);
        }

        const formData = new FormData();
        formData.append('email', values.username);
        formData.append('password', values.password);

        let result = await loginServices.login(formData);

        if (result.response.code === 200) {
            notifySuccess('Berhasil Login');
            let newState = { ...state };
            newState.user = {
                username: result.response.name,
                token: result.response.token,
                role: result.response.role,
                menu: result.response.menu,
            };
            dispatch({ type: SET_USER, payload: newState.user });
            setLoading(false);
            history.push('/dashboard');
        } else {
            setLoading(false);
            notifyError(result.response.message);
        }
    };

    const onChangeCheckbox = () => {
        setRemember(!remember);
    };

    if (!!state.user) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <LoginStyle>
            <Logo />
            <div className="container">
                <h4>Login Form</h4>
                <Divider />
                <Form form={form} onFinish={(e) => onFinish(e)}>
                    <FormBuilder meta={meta} form={form} />
                    <Form.Item
                        wrapperCol={{ span: 16, offset: 8 }}
                        name="remember"
                        valuePropName="checked">
                        <Checkbox
                            key="remember"
                            checked={true}
                            onChange={(e) => onChangeCheckbox(e)}>
                            Remember me
                        </Checkbox>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}>
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </LoginStyle>
    );
};

export default Login;